import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";

import { reviews } from "../data";
import Quote from "../img/right-quote.svg";
import "./ReviewSwiper.scss";

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

export default function ReviewSwiper() {
  const customer_reviews = reviews();
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        loop={true}
        className="mySwiper"
      >
        {customer_reviews.map((review, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={Quote} alt="quote" />
                <div className="review">
                  <p className="review-comment">{review.comment}</p>
                  <p className="review-author">{review.name}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
