import React from "react";
import "./Reviews.scss";
import ReviewSwiper from "./ReviewSwiper";

function Reviews() {
  return (
    <section id="reviews" className="reviews">
      <div className="section-header-container">
        <p className="section-header">Reviews</p>
      </div>
      <ReviewSwiper />
      <p className="msg">
        If you have received any of my services, I will really apreciate if you
        can take a few mins of your time to
        <a href="https://www.instagram.com/p/CJW_63ZpLjG/"> write a review </a>
        describing how your visit went.
      </p>
    </section>
  );
}

export default Reviews;
