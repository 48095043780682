import React from "react";
import SocialMedia from "../SocialMedia/SocialMedia";
import BackToTop from "../img/arrow.jpg";

import "./Footer.scss";

function Footer() {
  return (
    <section className="footer">
      <footer>
        <p className="follow">Follow</p>
        <SocialMedia />
        <ul className="call-to-action">
          {/* <li>
            <a href="#gallery">Visit Gallery</a>
          </li> */}
          {/* Following icon is originally made by https://www.flaticon.com/authors/kirill-kazachek */}
          <li className="back-to-top">
            <a href="#home">
              <img src={BackToTop} alt="arrow" />
            </a>
            <a href="#home">Back To Top</a>
          </li>
          {/* <li className="footer-cta">
            <a href="https://app.acuityscheduling.com/schedule.php?owner=21999116">
              Book an appointment
            </a>
          </li> */}
        </ul>
      </footer>

      <p className="copyright">Copyright &copy; 2021, BBJ.</p>
    </section>
  );
}

export default Footer;
