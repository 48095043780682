import Landing from "./Landing/Landing";
import AboutMe from "./AboutMe/AboutMe";
import Community from "./Community/Community";
import Galleries from "./Galleries/Galleries";
import Reviews from "./Reviews/Reviews";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import CTA from "./CTA/CTA";

import "./App.scss";
import Services from "./Services/Services";

function App() {
  return (
    <div>
      <Navbar />
      <hr />
      <Landing />
      <AboutMe />
      <Community />
      <Galleries />
      <Services />
      <Reviews />
      <CTA />
      <Footer />
    </div>
  );
}

export default App;
