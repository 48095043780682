import Gallery1 from "./img/gallery1.jpg";
import Gallery2 from "./img/gallery2.jpg";
import Gallery3 from "./img/gallery3.jpg";
import Gallery4 from "./img/gallery4.jpg";
import Gallery5 from "./img/gallery5.jpg";
import Gallery6 from "./img/gallery6.jpg";
import Gallery7 from "./img/gallery7.jpg";
import Gallery8 from "./img/gallery8.jpg";
import Gallery9 from "./img/gallery9.jpg";
import Gallery10 from "./img/gallery10.jpg";
import Gallery11 from "./img/gallery11.jpg";
import Gallery12 from "./img/gallery12.jpg";
import Gallery13 from "./img/gallery13.jpg";
import Gallery14 from "./img/gallery14.jpg";

export function gallery() {
  return [
    Gallery1,
    Gallery3,
    Gallery2,

    Gallery4,
    Gallery5,
    Gallery6,
    Gallery7,
    Gallery8,
    Gallery9,
    Gallery10,
    Gallery11,
    Gallery12,
    Gallery14,
    Gallery13,
  ];
}

export function services() {
  const result = [
    {
      type: "Fade",
      price: "40",
    },
    {
      type: "Taper",
      price: "40",
    },
    {
      type: "Fade W/ Facial Hair",
      price: "45",
    },
    {
      type: "Taper W/ Facial Hair",
      price: "45",
    },
    {
      type: "Haircut X Wash",
      price: "50"
    }
  ];
  return result;
}

export function reviews() {
  const result = [
    {
      name: "Louie",
      comment:
        "Super professional but very easy to get along with. Very entertaining! He cares about his clientele. And his work with the clippers is outstanding.",
    },
    {
      name: "Joel D.",
      comment: "Will always make sure his cut satisfies the customer.",
    },
    {
      name: "Jimmy B.",
      comment:
        "This guy since I’ve came to him has been nothing but polite and professional I did not know him prior to my appointment he took me on and not only is he a A1 10/10 barber but he’s a great friend! ",
    },
    {
      name: "Juan S.",
      comment:
        "Top quality service from a gentleman and a scholar! Safe environment and a true artist with the clippers. Would recommend 10/10!",
    },
    {
      name: "Salvador A.",
      comment:
        "Full of energy that got passed down to his clippers! Man has a realistic vision of where he is and where he wants to be. Natural speaker that keeps you talking till the point you feel Iike you’ve known him your whole life. Looking fresh and made an important connection the same day. Couldn’t ask for less.",
    },
    {
      name: "Eduardo R.",
      comment:
        "Realest, most caring, social, and passionate barber I’ve met & I look forward to getting to know you outside of the shop bro.",
    },
  ];
  return result;
}
