import React from "react";
import video from "../img/landingVideo.mp4";
import "./Landing.scss";

function Landing() {
  return (
    <section id="home" className="landing">
      <video src={video} type="video/mp4" muted loop autoPlay controls></video>
      <div className="overlay"></div>
      <div className="cta">
        <h2>Changing and inspiring </h2>
        <h3>lives one cut at a time.</h3>
        <p>
          Quality service. Quality experience.
          <br /> Look good, feel good, and live your life good.
        </p>
        <a href="https://app.acuityscheduling.com/schedule.php?owner=21999116">
          Book Now
        </a>
      </div>
    </section>
  );
}

export default Landing;
