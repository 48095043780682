import React from "react";
import "./Button.scss";

function PrimaryButton() {
  return (
    <div className="btn-primary">
      <a href="https://app.acuityscheduling.com/schedule.php?owner=21999116">
        BOOK NOW
      </a>
    </div>
  );
}

export default PrimaryButton;
