import React from "react";
import GallerySwiper from "./GallerySwiper";
import "./Galleries.scss";

function Galleries() {
  return (
    <section id="gallery" className="galleries">
      <div className="section-header-container">
        <p className="section-header">Galleries</p>
      </div>
      <GallerySwiper />
      <p className="msg">
        These are only a few of many. If you want to check out more pictures,
        head over to my <a href="https://instagram.com/bbjceo">instagram</a>
      </p>
    </section>
  );
}

export default Galleries;
