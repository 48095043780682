export default function hamburger() {
  const hamburger = document.querySelector(".hamburger");
  const navUl = document.querySelector("#nav-links");
  const navUlLinks = document.querySelectorAll("#nav-links li");

  //   toggle nav
  navUl.classList.toggle("hamburger-activated");
  // animate nav links
  navUlLinks.forEach((link, index) => {
    // if there is animation

    if (link.style.animation) {
      link.style.animation = "";
    } else {
      link.style.animation = `animateNavLinks 0.5s ease forwards ${
        index / 7 + 0.5
      }s`;
    }
  });
  // animate hamburger
  hamburger.classList.toggle("toggled");
}
