import React from "react";
import "./CTA.scss";

function CTA() {
  return (
    <section className="book-now">
      <div className="section-header-container">
        <p className="section-header">Booking</p>
      </div>
      <div className="book-now-content">
        <p className="quote">Life is not perfect, but your hair can be.</p>
        <div className="book-now-link">
          <a href="https://app.acuityscheduling.com/schedule.php?owner=21999116">
            BOOK AN APPOINTMENT NOW
          </a>
        </div>
      </div>
    </section>
  );
}

export default CTA;
