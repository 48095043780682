import React from "react";
import "./Services.scss";

import { services } from "../data";

function Services() {
  const all_services = services();
  return (
    <>
      <section id="services" className="services">
        <div className="section-header-container">
          <p className="section-header">Services</p>
        </div>
        <div className="service-container-wrapper">
          <div className="outer-container">
            <div className="upper-container">
              <div className="services-container">
                {all_services.map((service, index) => {
                  return (
                    <div key={index}>
                      <div className="service">
                        <p className="service-type">{service.type}</p>
                        <p className="service-price">${service.price}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="book-now-link">
              <a href="https://app.acuityscheduling.com/schedule.php?owner=21999116">
                BOOK AN APPOINTMENT NOW
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="dummy-cont"></div>
    </>
  );
}

export default Services;
