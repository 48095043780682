import React from "react";
import "./AboutMe.scss";
import Me1 from "../img/about1.jpg";
import Me2 from "../img/about2.jpg";
import Me3 from "../img/about3.jpg";

function AboutMe() {
  return (
    <section id="about-me" className="about-me">
      <div className="section-header-container">
        <p className="section-header">About Me</p>
      </div>
      <div className="about-container about-item0">
        <img className="about-img" src={Me1} alt="me" />
        <p className="about-content">
          Greetings! My name is Javier Lopez. I was born and raised in San
          Francisco. Growing up as a kid I pursued the art industry with a
          passion. I have discovered many art aspects throughout my life
          experiences.
        </p>
      </div>
      <div className="about-container about-item1">
        <p className="about-content">
          Becoming a barber has changed and improved my lifestyle. Both
          physically, and mentally. As a young adult, I try to improve everyday.
          I take my career very serious and enjoy what I do.
        </p>
        <img className="about-img" src={Me2} alt="me" />
      </div>
      <div className="about-container about-item2">
        <img className="about-img" src={Me3} alt="me" />
        <p className="about-content">
          The diversity of Clientele is what makes this career so interesting
          and exciting. Learning new things about different individuals is one
          of the many reasons on why I pursued barbering as a career. I look
          forward on seeing you on my next available appointment! Cheers!
        </p>
      </div>
    </section>
  );
}

export default AboutMe;
