import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "./Navbar.scss";
import "../Landing/Landing.scss";
import hamburger from "./hamburger";
import PrimaryButton from "../Button/PrimaryButton";

function Navbar() {
  const handleScroll = () => {
    const nav_bar = document.querySelector("nav");
    nav_bar.classList.toggle("sticky", window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav>
      <div className="logo">
        <Link to="home">BBJ</Link>
      </div>
      <ul id="nav-links">
        <li>
          <Link to="home">HOME</Link>
        </li>
        <li>
          <Link to="about-me">ABOUT ME</Link>
        </li>
        <li>
          <Link to="community">COMMUNITY</Link>
        </li>
        <li>
          <Link to="gallery">GALLERY</Link>
        </li>
        <li>
          <Link to="services">SERVICES</Link>
        </li>
        <li>
          <Link to="reviews">REVIEWS</Link>
        </li>
        <li>
          <PrimaryButton />
        </li>
      </ul>
      <div className="hamburger" onClick={hamburger}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;
