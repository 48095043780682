import React from "react";
import { SocialIcon } from "react-social-icons";
import "./SocialMedia.scss";

function SocialMedia() {
  return (
    <ul className="social-icons">
      <li>
        <SocialIcon url="https://instagram.com/bbjceo" />
      </li>
      <li>
        <SocialIcon url="https://twitter.com/510_javi" />
      </li>
      <li>
        <SocialIcon url="https://www.tiktok.com/@bbjceo" />
      </li>
      <li>
        <SocialIcon url="https://www.youtube.com/channel/UCYiZlBYkgQ-mZSH1JMylYGQ" />
      </li>
    </ul>
  );
}

export default SocialMedia;
