import React from "react";
import "./Community.scss";
import Community1 from "../img/community1.jpg";
import Community2 from "../img/community2.jpg";

function Community() {
  return (
    <section id="community" className="community">
      <div className="section-header-container">
        <p className="section-header" style={{ color: "black" }}>
          Community
        </p>
      </div>
      <div className="community-outer-container">
        <div className="community-inner-container com-item-1">
          <img src={Community1} alt="community" className="community-img" />
          <div className="caption">
            <br />
            Bbj's participation in
            <a href="https://www.richmondsol.org/semipro"> Richmond Sol's </a>
            Annual barbercon giving kids free haircuts and inspiring them to
            become positive wise teenagers.
          </div>
        </div>
        <div className="community-inner-container com-item-2">
          <img src={Community2} alt="community" className="community-img" />
          <div className="caption">
            <br />
            Inspiring greatness and positivity one kid , one cut at a time.
          </div>
        </div>
      </div>
    </section>
  );
}

export default Community;
